import React from 'react';
import {Download, Features, SectionWrapper} from './components';
import assets from './assets';
import styles from './styles/Global';
const App = () => {
  return (
    <React.Fragment>
      <SectionWrapper
        title= "Unlock New Possibilities with an NFT Marketplace Mobile App"
        description= "Buy, store, collect NFTs, exchange & earn crypto. Join 25+ million people using ProNef Marketplace."
        showBtn
        mockupImg = {assets.homeHero}
        banner="banner"
      />
       <SectionWrapper
        title= "Smart User Interface Marketplace"
        description= "Experience a seamless UI of ProNet NFT Marketplace, with a vibrant color palette for a truly immersive experience."
        mockupImg = {assets.homeCards}
        reverse
      />
      <Features/>
      <SectionWrapper
        title="Deployment"
        description="ProNef is built using Expo which runs natively on all users' devices. You can easily get your app into people's hands"
        mockupImg={assets.feature}
        reverse
      />
       <SectionWrapper
        title="Creative way to showcase the store"
        description="The app contains two screens. The first screen lists all NFTs while the second one shows the details of a specific NFT."
        mockupImg={assets.mockup}
        banner="banner02"
      />
       <Download />

      <div className="px-4 py-2 justify-center items-center bg-primary flex-col text-center banner04">
        <p className={`${styles.footerText} ${styles.whiteText}`}>Made with love by {" "}
        <span className="font-bold cursor-pointer" onClick={() => window.open('https://www.linkedin.com/in/aadila-bendahou/', "_blank")}>Aâdila Bendahou </span>
         &copy; 2022
        </p>
      </div>
    </React.Fragment>
  );
}

export default App;
